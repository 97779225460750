import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {finalize, catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  authenticated = false;

  username:string;
  constructor(private http: HttpClient, private router:Router, private snackBar: MatSnackBar) {
  }

  authenticate(username:string,password:string, callback) {

        const headers = new HttpHeaders(username&&password ? {
            authorization : 'Basic ' + btoa(username + ':' + password)
        } : {});

        this.http.get('api/user', {headers: headers}).pipe(catchError(
            (error:any) => {
                this.logout();
                return Observable.throw(error);

            }
        )).subscribe(response => {
            if (response['username']) {
                this.authenticated = true;
                this.username=response['username'];
            } else {
                this.snackBar.open("Benutzername oder Passwort ist falsch.")
                this.authenticated = false;
                this.router.navigateByUrl('/login');
            }
            return callback && callback();
        });

    }

    logout() {
        this.http.post('logout', {}).pipe(finalize(() => {
            this.authenticated = false;
            this.username="";
            this.router.navigateByUrl('/login');
        })).subscribe();
      }
}
