import { Injectable } from '@angular/core';
import { finishResponse } from './passwordService.responses';
import { Observable, of } from 'rxjs';
import { map,catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private loginService:LoginService, private http: HttpClient) { }

  changePassword(old_password:string, new_password:string) {
    return this.http.post("/api/password",{old_password:old_password,new_password:new_password}).pipe(map((response: finishResponse) => {
          return true;
      })).pipe( 
        catchError((error:any) => {
        this.loginService.logout();
        return of(false);
      }));
  }
}
