import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { ChangePasswordService } from './change-password.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  old_password:string;
  password:string="";
  password2:string="";
  password_regex:RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
  error_message:string;
  constructor(public loginService:LoginService,private changePasswordService:ChangePasswordService,private snackBar: MatSnackBar) {

  }

  ngOnInit() {
  }

  async changePasswordBtClick()
  {
    if (!this.password_regex.test(this.password)) {
      this.snackBar.open('Das Passwort ist nicht komplex genug (mind. 8 Zeichen, ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl)', 'OK');
      return;
    }

    if (this.password==this.password2) {
     let result = await this.changePasswordService.changePassword(this.old_password,this.password).toPromise();
     if (result) {
       this.loginService.logout();
      this.snackBar.open('Passwort wurde erfolgreich geaendert', 'OK');
     } else {
      this.snackBar.open('Das Passwort konnte nicht geaendert werden', 'OK');
     }
    } else {
      this.snackBar.open('Passwoerter stimmen nicht ueberein', 'OK');
    }
  }
}
